// import { Environment } from './../../config';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestService } from '../core/rest.service';
import { map, catchError } from 'rxjs/operators';
import { AllCategories } from '../interface/product-list.interface';
import { RestResponse } from '../interface/RestResponse.interface';
import { HttpClient } from '@angular/common/http';
import { PaginationResponse } from '../interface/pagination-response.interface';
import { BulkEditRequest } from '../interface/bulkEdit.interface';
import { IBuyer } from '../interface/manage-buyer.interface';
import { ILocation } from '../interface/manage-vendor.interface';
import { IHealthBenefits } from '../interface/product.interface';
import { Environment } from 'src/environments/environment';
import { IAdmin, IService } from '../interface/service.interface';
import { IExpertsDetails } from '../interface/expert.interface';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  paymentMode = [
    { value: 'Pay Online', paymentModeId: 'ONLINE' },
    { value: 'Cash on Delivery', paymentModeId: 'COD' },
  ];
  productType = [
    { value: 'Retail', productTypeId: 'retail' },
    { value: 'Wholesale', productTypeId: 'wholeSale' },
    { value: 'Kalpav Wholesale', productTypeId: 'kalpavWholesale' },
  ];
  vendorType = [
    { value: 'Retailer', vendorTypeId: 'RETAILER' },
    { value: 'Wholesaler', vendorTypeId: 'WHOLESALER' },
    { value: 'Kalpav Wholesaler', vendorTypeId: 'KALPAV_WHOLESALE' },
  ];
  orderType = [
    { value: 'Retail', orderTypeId: 'RETAIL' },
    { value: 'Wholesale', orderTypeId: 'WHOLESALE' },
    { value: 'Kalpav Wholesale', orderTypeId: 'KALPAV_WHOLESALE' },
  ];
  constructor(private restService: RestService, private http: HttpClient) { }

  public getAllCategories(type = '') {
    return this.restService
      .get(Environment.UrlPath + `/product/category`, { type })
      .pipe(
        map((res: RestResponse<Array<AllCategories>>) => res),
        catchError((error) => throwError(error))
      );
  }

  public getProductTypeCategories(category: any) {
    return this.restService
      .get(Environment.UrlPath + `/product`, category)
      .pipe(
        map((res: RestResponse<Array<AllCategories>>) => res),
        catchError((error) => throwError(error))
      );
  }
  public getHealthBenefits(): Observable<RestResponse<IHealthBenefits[]>> {
    return this.restService
      .get(Environment.UrlPath + `/master/healthbenefits`, false)
      .pipe(
        map((res: RestResponse<IHealthBenefits[]>) => res),
        catchError((error) => throwError(error))
      );
  }
  public getAllProducts(data): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/product`, data, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getAllVendorProducts(vendorId, data): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/productAvailability/vendor/${vendorId}`,
        data,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public listPendingApprovalProducts(data): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
        `/productAvailability/listPendingApprovalProducts`,
        data,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public filterBuyers(
    pageNumber,
    pageSize,
    keyword,
    status,
    from,
    to
  ): Observable<RestResponse<PaginationResponse<Array<IBuyer>>>> {
    return this.restService
      .get(
        Environment.UrlPath + `/buyer`,
        { pageNumber, pageSize, keyword, status, from, to },
        false
      )
      .pipe(
        map((res: RestResponse<PaginationResponse<Array<IBuyer>>>) => res),
        catchError((error) => throwError(error))
      );
  }

  public getAllProduct(data): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/product/filterMasterproducts`, data, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public modifyProductStatus(
    status: string,
    productId: string
  ): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/product/${productId}/status?productStatus=${status}`,
        false,
        null
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getProductById(id: string): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/product/${id}`, false, true)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public listProductSubCategories(categoryId: string): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/product/subCategory?category=${categoryId}`,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public addProduct(addProductData: FormData): Observable<any> {
    return this.restService
      .post(Environment.UrlPath + '/product', addProductData, false, true)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public editProduct(editProductData: FormData, id: string): Observable<any> {
    return this.restService
      .put(Environment.UrlPath + `/product/${id}`, editProductData, false, true)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public addVendor(addVendorData): Observable<any> {
    return this.restService
      .post(Environment.UrlPath + '/vendor', addVendorData, false, true, null)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getAllVendor(
    data: { pageNumber: any; status: any; keyword: any; userType?: string },
    disableLoader = false
  ): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/vendor`, data, disableLoader)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getAllVendorPendingForApproval(data: {
    pageNumber: any;
    pageSize: any;
    keyword: any;
  }): Observable<any> {
    return this.restService.get(Environment.UrlPath + `/vendor`, data).pipe(
      map((res) => res),
      catchError((error) => throwError(error))
    );
  }
  public getVendorById(vendorId: string): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/vendor/${vendorId}`, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public editVendor(data: FormData, vendorId: string): Observable<any> {
    return this.restService
      .put(Environment.UrlPath + `/vendor/${vendorId}`, data, false, true)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public modifyVendorStatus(data: {
    vendorId: any;
    vendorStatus: any;
    remark?: string;
  }): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/vendor/${data.vendorId}/status?vendorStatus=${data.vendorStatus}&remark=${data.remark}`,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  public getBankDetails(code: string) {
    return this.http.get(Environment.ifscUrl + code).pipe(
      map((data) => data),
      catchError((error) => {
        console.log(error, 'error');

        return throwError(error);
      })
    );
  }
  public updateAdminApprovalStatus(
    productApprovalStatus,
    remark,
    vendorProductId
  ): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/productAvailability/${vendorProductId}/approvalStatus?productApprovalStatus=${productApprovalStatus}&adminRemarks=${remark}`,
        false,
        null
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  public getProductsForBulkEdit(data): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + '/productAvailability/bulkEdit/retail',
        data,
        false
      )
      .pipe(
        map(
          (res: RestResponse<PaginationResponse<Array<BulkEditRequest>>>) => res
        ),
        catchError((error) => throwError(error))
      );
  }
  public blockBuyer(adminRemarks, buyerId): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/user/${buyerId}/block?adminRemarks=${adminRemarks}`,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public unBlockBuyer(buyerId): Observable<any> {
    return this.restService
      .put(Environment.UrlPath + `/user/${buyerId}/unblock`, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getBuyerOrders(
    pageNumber,
    pageSize,
    keyword,
    status,
    from,
    to,
    vendorKeyword,
    paymentMode,
    orderType?: string
  ): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/order`,
        {
          pageNumber,
          pageSize,
          keyword,
          status,
          from,
          to,
          vendorKeyword,
          paymentMode,
          orderType
        },
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getVendorOrders(
    vendorId,
    pageNumber,
    pageSize,
    keyword,
    status,
    from,
    to,
    paymentMode,
  ): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath +
        `/order/vendor/${vendorId}?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&status=${status}&from=${from}&to=${to}`,
        null,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  public getBuyerOrderList(
    buyerId,
    pageNumber,
    pageSize,
    orderId,
    status,
    from,
    to
  ): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/order/${buyerId}`,
        { pageNumber, pageSize, orderId, status, from, to },
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  getHsnCode(hsnKeyWord): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/product/listHsnCodes`, { hsnKeyWord }, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  getOrderItems(orderId): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/order/items/${orderId}`, null, false)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  getVendorOrderItems(vendorId, orderId): Observable<any> {
    return this.restService
      .get(
        Environment.UrlPath + `/order/${vendorId}/items/${orderId}`,
        null,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  productPopular(id, status): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/product/${id}/popular?isPopularProduct=${status}`,
        null,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  changeOrderStatus(id, status, remark): Observable<any> {
    return this.restService
      .put(
        Environment.UrlPath +
        `/order/validate/${id}?orderStatus=${status}&remark=${remark}`,
        null,
        false
      )
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }
  downloadMasterProduct(filterData: {
    pageNumber: number;
    pageSize: number;
    category: number;
    subCategory: number;
    keyword: string;
    status: string;
  }) {
    return (
      Environment.UrlPath +
      `/product/master/excel?pageNumber=${filterData.pageNumber}&pageSize=${filterData.pageSize}&category=${filterData.category}&subCategory=${filterData.subCategory}&keyword=${filterData.keyword}&status=${filterData.status}`
    );
  }
  downloadVendor(filterData: {
    pageNumber: number;
    pageSize: number;
    keyword: string;
    status: string;
  }) {
    return (
      Environment.UrlPath +
      `/vendor/excel/download?pageNumber=${filterData.pageNumber}&pageSize=${filterData.pageSize}&keyword=${filterData.keyword}&status=${filterData.status}`
    );
  }
  downloadOrders(filterData: {
    pageNumber: number;
    pageSize: number;
    keyword: any;
    status: any;
    vendorKeyword: any;
    fromDate: string | number;
    toDate: string | number;
  }) {
    return this.restService
      .get(
        Environment.UrlPath +
        `/order/excel/download?pageNumber=${filterData.pageNumber}&pageSize=${filterData.pageSize}&keyword=${filterData.keyword}&status=${filterData.status}&vendorKeyword=${filterData.vendorKeyword}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}`,
        null,
        false,
        true
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  downloadBuyers(filterData: {
    keyword: any;
    status: any;
    fromDate: string | number;
    toDate: string | number;
  }) {
    return this.restService
      .get(
        Environment.UrlPath +
        `/buyer/excel/download?keyword=${filterData.keyword}&status=${filterData.status}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}`,
        null,
        false,
        true
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  getLocationOnPinCode(
    pinCode: string
  ): Observable<RestResponse<Array<ILocation>>> {
    return this.restService
      .get(Environment.UrlPath + `/logistics/pincode/${pinCode}`, null, false)
      .pipe(
        map((res: RestResponse<Array<ILocation>>) => res),
        catchError((error) => throwError(error))
      );
  }
  editBuyer(
    buyerId: string,
    updatedBuyerDetails
  ): Observable<RestResponse<string>> {
    return this.restService
      .put(
        Environment.UrlPath + `/buyer/${buyerId}/update`,
        updatedBuyerDetails,
        false
      )
      .pipe(
        map((res: RestResponse<string>) => res),
        catchError((error) => throwError(error))
      );
  }
  public getManageAdmin(
    pageNumber: number,
    pageSize: number
  ): Observable<RestResponse<PaginationResponse<IAdmin[]>>> {
    return this.restService
      .get(
        Environment.UrlPath +
        `/user/admin/view?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        null,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public addNewAdmin(data: any): Observable<RestResponse<string>> {
    return this.restService
      .post(Environment.UrlPath + `/user/admin`, data, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public editAdmin(
    data: any,
    userId: string
  ): Observable<RestResponse<string>> {
    return this.restService
      .put(Environment.UrlPath + `/user/admin/${userId}`, data, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  public getByAdminsId(id: string): Observable<RestResponse<IAdmin>> {
    return this.restService
      .get(Environment.UrlPath + `/user/admin/${id}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public getAllServices(): Observable<RestResponse<IService[]>> {
    return this.restService
      .get(Environment.UrlPath + `/user/sevices`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public getAllExpertsData(
    pageNumber: number
  ): Observable<RestResponse<PaginationResponse<IExpertsDetails[]>>> {
    return this.restService
      .post(
        Environment.UrlPath +
        `/user/expert/view?pageNumber=${pageNumber}&pageSize=50`,
        null,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public getExpertsDataById(
    id: string
  ): Observable<RestResponse<IExpertsDetails>> {
    return this.restService
      .get(Environment.UrlPath + `/user/expert/${id}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
}
