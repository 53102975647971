import { Injectable } from '@angular/core';
import { BlogService } from './blog.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private userService: UserService, private blogService: BlogService) { }
  userId: string;
  userName;
  userData;
  designation: string;
  acessToken: string;
  csrfToken: string;
  userRole;
  emailId: string;
  public Config = {
    toolbar: [
      {
        name: 'clipboard',
        items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'],
      },
      { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
        ],
      },
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    extraPlugins: ['justify', 'clipboard'],
    removePlugins: 'elementspath',
  };

  saveLoginData(resArray, emailId?) {
    if (resArray) {
      sessionStorage.setItem('access_token', resArray.access_token);
      sessionStorage.setItem('csrf_token', resArray.csrf_token);
      sessionStorage.setItem('user_role', resArray.user_role.roleId);
      sessionStorage.setItem('user_Id', resArray.user_id);
      sessionStorage.setItem('user_type', resArray.user_type);
      sessionStorage.setItem('emailId', emailId);
      sessionStorage.setItem('vendorName', '');
    }
  }
  setVendorName(vendorName: string) {
    sessionStorage.setItem('vendorName', vendorName);
  }
  get getUserName() {
    return sessionStorage.getItem('vendorName');
  }
  getFromLocalStorage() {
    this.userId = sessionStorage.getItem('user_Id');
    this.acessToken = sessionStorage.getItem('access_token');
    this.csrfToken = sessionStorage.getItem('csrf_token');
    this.userRole = sessionStorage.getItem('user_role');
    this.userData = sessionStorage.getItem('user_type');
    this.emailId = sessionStorage.getItem('emailId');
  }
  saveUserInLocalStorage(data) {
    sessionStorage.setItem('user_details', data);
  }
  get getRoleId() {
    return this.userRole;
  }
  get getDesignation() {
    return this.designation;
  }
  get getUserId() {
    return sessionStorage.getItem('user_Id');
  }
  get getType() {
    return sessionStorage.getItem('user_type');
  }

  get getAccessToken() {
    return sessionStorage.getItem('access_token');
  }

  public clearOut() {
    delete this.userData;
    delete this.userId;
    delete this.userName;
    delete this.userRole;
    delete this.designation;
    delete this.acessToken;
    delete this.csrfToken;
  }
} 

