import { Injectable } from '@angular/core';
import { RestService } from '../core/rest.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { RestResponse } from '../interface/RestResponse.interface';
import { Environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { IBlog, IBlogCategory } from '../interface/blog.interface';
import { PaginationResponse } from '../interface/pagination-response.interface';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private restService: RestService, private http: HttpClient) { }

  public getAllBlogs(payload?: any): Observable<RestResponse<PaginationResponse<IBlog[]>>> {
    return this.restService
      .get(Environment.UrlPath + `/blog`, payload, false)
      .pipe(
        map((res: RestResponse<PaginationResponse<IBlog[]>>) => res),
        catchError((error) => throwError(error))
      );
  }
  public postBlog(payload: any): Observable<RestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/blog`, payload, true, true, null)
      .pipe(
        map((res: RestResponse<any>) => res),
        catchError((error) => throwError(error))
      );
  }
  public getBlog(blogId: string): Observable<RestResponse<IBlog>> {
    return this.restService
      .get(Environment.UrlPath + `/blog/${blogId}`, false)
      .pipe(
        map((res: RestResponse<IBlog>) => res),
        catchError((error) => throwError(error))
      );
  }
  public editBlog(blogId: string, payload): Observable<RestResponse<IBlog>> {
    return this.restService
      .put(Environment.UrlPath + `/blog/${blogId}`, payload, true, true)
      .pipe(
        map((res: RestResponse<IBlog>) => res),
        catchError((error) => throwError(error))
      );
  }
  public activateBlog(blogId: string): Observable<RestResponse<IBlog>> {
    return this.restService
      .put(Environment.UrlPath + `/blog/activate/${blogId}`, false)
      .pipe(
        map((res: RestResponse<IBlog>) => res),
        catchError((error) => throwError(error))
      );
  }
  public deactivateBlog(blogId: string): Observable<RestResponse<IBlog>> {
    return this.restService
      .put(Environment.UrlPath + `/blog/inactivate/${blogId}`, false)
      .pipe(
        map((res: RestResponse<IBlog>) => res),
        catchError((error) => throwError(error))
      );
  }

  public getAllBlogCategories(): Observable<RestResponse<IBlogCategory[]>> {
    return this.restService
      .get(Environment.UrlPath + `/blog/category`, null, false)
      .pipe(
        map((res: RestResponse<IBlogCategory[]>) => res),
        catchError((error) => throwError(error))
      );
  }
  public postBlogCategory(payload: any): Observable<RestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/blog/category`, payload, true, true, null)
      .pipe(
        map((res: RestResponse<any>) => res),
        catchError((error) => throwError(error))
      );
  }
  public uploadBlogImages(payload: FormData): Observable<RestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/blog/image`, payload, true, true, false)
      .pipe(
        map((res: RestResponse<any>) => res),
        catchError((error) => throwError(error))
      );
  }


}
